import { XMLParser } from 'fast-xml-parser';
import axios from 'axios';

import Parallax from 'parallax-js'

document.addEventListener("DOMContentLoaded", function(event) {
  const scrollTo = document.querySelector( '.hero__more' );
  const sectionAboutUs = document.querySelector( '.about-us' );
  const scrollToTop = document.querySelector( '.to-top' );
  if ( scrollTo ) {
    scrollTo.addEventListener( 'click', ( event ) => {
      event.preventDefault();
      sectionAboutUs.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    } );
  }
  if ( scrollToTop ) {
    scrollToTop.addEventListener( 'click', ( event ) => {
      event.preventDefault();
      window.scroll( { top: 0, behavior: 'smooth' } );
    } );
  }

  const scene = document.getElementById('scene');
  new Parallax(scene);

});



// const parser = new XMLParser();
// axios.get( 'https://www.investing.com/rss/market_overview_Technical.rss', {
//   headers: { 'content-Type': 'application/rss+xml' },
// } ).then( ( data ) => {
//   console.log( data );
// } );


// let jObj = parser.parse( 'https://www.investing.com/rss/market_overview_Technical.rss' );
// console.log( jObj );